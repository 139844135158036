<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-card-text class="m-1">
      Pinjaman Terbaru
    </b-card-text>
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
      empty-text="No matching records found"
    >
      <!-- company -->
      <template #cell(requester)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.requester.name }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.requester.nim }}
            </div>
          </div>
        </div>
      </template>

      <!-- category -->
      <template #cell(booking_date)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.booking_date }}</span>
        </div>
      </template>

      <!-- views -->
      <template #cell(loan_date)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">{{ data.item.loan_start }}</span>
          <span class="font-small-2 text-muted text-nowrap">s/d {{ data.item.loan_end }}</span>
        </div>
      </template>

      <!-- revenue -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolverStatus(data.item.status)}`"
          class="text-capitalize"
        >
          {{ translateStatus(data.item.status) }}
        </b-badge>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BTable, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: 'requester', label: 'Peminjam' },
        { key: 'booking_date', label: 'Tanggal Pengajuan' },
        { key: 'loan_date', label: 'Tanggal Peminjaman' },
        { key: 'status', label: 'Status' },
      ],
    }
  },
  methods: {
    resolverStatus(status) {
      if (status === 'APPROVED') {
        return 'success'
      }

      if (status === 'REQUEST') {
        return 'warning'
      }

      if (status === 'REJECTED') {
        return 'danger'
      }

      if (status === 'REQUEST RETURN') {
        return 'warning'
      }

      if (status === 'RETURNED') {
        return 'primary'
      }

      return 'primary'
    },
    translateStatus(status) {
      if (status === 'APPROVED') {
        return 'Disetujui'
      }

      if (status === 'REQUEST') {
        return 'Menunggu Persetujuan'
      }

      if (status === 'REJECTED') {
        return 'Ditolak'
      }

      if (status === 'REQUEST RETURN') {
        return 'Menunggu Persetujuan Pengembalian'
      }

      if (status === 'RETURNED') {
        return 'Dikembalikan'
      }

      return 'Dibatalkan'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>

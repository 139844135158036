<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col
        lg="6"
        md="12"
      >
        <student-statistics :data="statistics" />
      </b-col>
      <b-col lg="6" md="12">
        <student-loan-table :table-data="latest_loans" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import StudentStatistics from './StudentStatistics.vue'
import StudentLoanTable from './StudentLoanTable.vue'

export default {
  components: {
    BRow,
    BCol,

    StudentStatistics,
    StudentLoanTable,
  },
  data() {
    return {
      data: {},
      statistics: [],
      latest_loans: [],
      refinements: [],
      debt: {},
    }
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.name
      })

    this.$http.get('/v1/dashboard/student')
      .then(response => {
        this.statistics = response.data.data.statistics
        this.latest_loans = response.data.data.latest_loans
        this.debt = response.data.data.debt
        this.refinements = response.data.data.refinements
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
